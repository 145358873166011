import * as React from "react";
import { Link, graphql } from "gatsby";

import { Header } from "../../sections/Header";

import Layout from "../../layouts/Layout";

const Blog = ({ data }) => {
  return (
    <Layout>
      <Header dark />

      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-full" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Blog aplikacji Whistleblower
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Whistleblower to bezpieczny kanał komunikacji udostępniony wewnątrz Twojej firmy, spełniający wszystkie wymagania ustawy o sygnalistach. Na blogu znjadziesz więcej informacji na temat zgodności z ustawą.
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {data.allWpPost.nodes.map((node) => (
              <div
                key={node.slug}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  {node.featuredImage && (
                    <img
                      className="h-48 w-full object-cover"
                      src={node.featuredImage.node.sourceUrl}
                      alt=""
                    />
                  )}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-blue-500">
                      <span>{node.categories.nodes[0].name}</span>
                    </p>
                    <Link to={node.slug} className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {node.title}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        className="mt-3 text-base text-gray-500"
                      />
                    </Link>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={node.author.node.avatar.url}
                        alt="Avatar"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time>{node.date}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`;
